import Mounter from "./Mounter";
import Vue from "@vue";

const VueApp = {
  start() {
    let mounter = new Mounter();
    mounter.mountComponents();
  }
};

export default VueApp;
