import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ['button']

  toggle(e) {
    let element_klass = e.target.dataset.element

    this.element.querySelectorAll(element_klass).forEach((elem) => {
      elem.classList.toggle('d-none')
    })
  }

}
