<template>
  <section class="sidebar-filter-toggle">
    <button class="btn btn-light btn-sm" @click="showFilterSection" v-show="hidden">
      <span>Filter</span>
    </button>
    <button class="btn btn-light btn-sm" @click="hideFilterSection" v-show="!hidden">Hide Filter</button>
  </section>
</template>

<script>
export default {
  name: "SidebarFilterToggle",
  data() {
    return {
      hidden: true
    }
  },
  methods: {
    showFilterSection() {
      const section = document.querySelector(".sidebar-filter-container");
      section.style.display = "block";
      this.hidden = false;
    },
    hideFilterSection() {
      const section = document.querySelector(".sidebar-filter-container");
      section.style.display = "none";
      this.hidden = true;
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebar-filter-toggle {
  display: inline-block;
  margin-right: 0.5rem;

  button {
    display: flex;
  }
}
</style>
