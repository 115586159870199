const DeviseAuthMixin = {
  data() {
    return {
      authToken: ""
    }
  },
  mounted() {
    this.fetchAuthToken()
  },
  methods: {
    fetchAuthToken() {
      this.authToken = document.querySelector("meta[name='csrf-token']")
        .getAttribute("content")
    }
  }
}

export default DeviseAuthMixin
