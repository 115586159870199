<template>
  <div class="notifications-list">
    <ul>
      <li v-for="notification in notifications">
        <a :href="notification.path">
          <div class="details">
            <div class="user-with-timestamp">
              <span class="timestamp text-muted">
                {{ notification.created_at | toLocalTime }}
              </span>
            </div>
            <span class="text">
              {{ notification.text }}
            </span>
          </div>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import DateFormatterMixin from "../mixins/DateFormatterMixin";

export default {
  name: "NotificationsList",
  props: ["notifications"],
  mixins: [DateFormatterMixin],
};
</script>

<style lang="scss" scoped>

$dark-mode-bg-color: #2a2e32;
$dark-mode-border-color: #44484d;

.notifications-list {
  ul {
    list-style: none;
    margin-bottom: 0;
    padding: 0;
    background: white;
  }

  li a {
    display: flex;
    border-bottom: 1px solid #eee;
    padding: 0.5rem 1.25rem;
    text-decoration: none;

    &:hover {
      cursor: pointer;
      background: #eee;
    }

    .details .text {
      font-size: 0.9rem;
    }

    .user-with-timestamp .timestamp {
      color: rgb(101, 119, 134);
    }
  }
}

body.dark-mode {
  .notifications-list {
    li a {
      color: inherit;
      background-color: $dark-mode-bg-color;
      border-bottom: 1px solid $dark-mode-border-color;

      &:hover {
        background-color: lighten($dark-mode-bg-color, 2.5%);
      }
    }
  }
}
</style>


