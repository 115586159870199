import Rails from 'rails-ujs'
import LocalTime from 'local-time'
import VueApp from '../vue/App'
import $ from 'jquery'
import Select2 from "select2"
import 'select2/dist/css/select2.css'
import Select2Mounter from "./select2-mounter"

// rails-ujs and local-time initializations
document.addEventListener('DOMContentLoaded', function () {
  Rails.start()
  LocalTime.start()
  VueApp.start()
  Select2Mounter.start()
})
