<template>
  <div class="notifications">
    <span class="notification-icon" @click="loadAndOpenNotificationPanel">
      <i data-feather="bell"></i>
    </span>
    <NotificationsPanel
      v-show="hideNotificationsPanel"
      @closeNotificationPanel="closeNotificationPanel"
      :notificationsPath="notificationsPath"
      :notifications="notifications"
    ></NotificationsPanel>
  </div>
</template>

<script>
import axios from "axios";
import NotificationsPanel from "./NotificationsPanel";
import DeviseAuthMixin from "../mixins/DeviseAuthMixin"

export default {
  name: "NotificationBell",
  props: ["notificationsPath"],
  mixins: [ DeviseAuthMixin ],
  components: {
    NotificationsPanel
  },
  data() {
    return {
      notifications: null,
      hideNotificationsPanel: false
    };
  },
  methods: {
    loadAndOpenNotificationPanel() {
      axios.get("/dashboard/activities.json", {
        headers: { "X-CSRF-TOKEN": this.authToken }
      }).then(response => {
        this.notifications = response.data;
        this.hideNotificationsPanel = !this.hideNotificationsPanel;
      });
    },
    closeNotificationPanel() {
      this.hideNotificationsPanel = !this.hideNotificationsPanel;
    }
  }
};
</script>

<style lang="scss" scoped>
.notifications {
  position: relative;

  .notification-icon {
    cursor: pointer;
    margin-right: 0.5rem;

    a {
      display: flex;
      align-items: center;
    }

    .feather {
      stroke: #333;
      fill: #333;
    }
  }
}

$dark-mode-light-color: #7D7F81;
$dark-mode-lighter-color: #BEBDBE;

body.dark-mode {
  .notifications {
    .notification-icon {
      .feather {
        stroke: $dark-mode-light-color;
        fill: $dark-mode-light-color;

        &:hover {
          stroke: $dark-mode-lighter-color;
          fill: $dark-mode-lighter-color;
        }
      }
    }
  }
}
</style>
