import Vue from "@vue";
import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";
import kebabCase from "lodash/kebabCase";

class Mounter {
  constructor() {}

  setWatchList() {
    const requireComponent = require.context(
      "./components",
      true,
      /\w+\.vue$/
    );

    const vueComponents = {};

    requireComponent.keys().map(filename => {
      const importName = upperFirst(
        camelCase(filename.replace(/^\.\/(.*)\.\w+$/, "$1"))
      );
      const componentName = kebabCase(importName);
      const componentConfig = requireComponent(filename);
      vueComponents[componentName] = componentConfig.default;
    });

    this.vueComponents = vueComponents;
  }

  mountComponents() {
    this.setWatchList();
    const vueComponents = document.querySelectorAll("[data-vue-component]");

    if (vueComponents.length <= 0) {
      return;
    }

    vueComponents.forEach(vueComponent => {
      this.mountComponent(vueComponent);
    });
  }

  mountComponent(vueComponent) {
    const name = vueComponent.getAttribute("data-vue-component");
    const props = JSON.parse(vueComponent.getAttribute("data-vue-props"));

    const componentObj = this.vueComponents[name];

    Vue.component(name, componentObj);

    new Vue({
      el: `#${vueComponent.id}`,
      render: createElement => {
        return createElement(name, { props: props });
      }
    });
  }
}

export default Mounter;
