<template>
  <div class="tags-input-field">
    <input
      type="hidden"
      :name="inputName"
      :id="inputId"
      :value="collectTagValues()"
    />
    <vue-tags-input v-model="tag" :tags="tags" @tags-changed="newTags => tags = newTags" />
  </div>
</template>

<script>
import VueTagsInput from "@johmun/vue-tags-input";

export default {
  name: "ActAsTaggableField",
  components: { VueTagsInput },
  props: {
    inputName: {
      type: String,
      required: true
    },
    inputId: {
      type: String,
      required: true
    },
    currentTags: {
      type: Array
    }
  },
  data() {
    return {
      tag: "",
      tags: this.currentTags || []
    };
  },
  methods: {
    collectTagValues() {
      return this.tags.map(tag => tag.text);
    }
  }
};
</script>

<style lang="scss">
$dark-mode-background-color: #393e44;
$dark-mode-text-color: #BEBDBE;

.ti-input {
  border-radius: 0.25rem !important;
  padding: 6px !important;
}

body.dark-mode {
  .ti-input {
    background-color: $dark-mode-background-color;
    color: $dark-mode-text-color;
    border: 0;
  }

  .ti-new-tag-input {
    background-color: $dark-mode-background-color;
    color: $dark-mode-text-color;
  }

  .vue-tags-input {
    background-color: $dark-mode-background-color;
    color: $dark-mode-text-color;
    border-radius: 0.25rem;
  }
}
</style>
