import $ from 'jquery';

const Select2Mounter = {
  // https://stackoverflow.com/a/15252131
  fuzzyMatch(string, query) {
    var hay = string.toLowerCase(), i = 0, n = -1, l;
    query = query.toLowerCase();
    for (; l = query[i++] ;) if (!~(n = hay.indexOf(l, n + 1))) return false;
    return true;
  },

  // https://select2.org/searching
  fuzzyMatcher(params, data) {
    // If there are no search terms, return all of the data
    if ($.trim(params.term) === '') {
      return data;
    }

    // Do not display the item if there is no 'text' property
    if (typeof data.text === 'undefined') {
      return null;
    }

    // `params.term` should be the term that is used for searching
    // `data.text` is the text that is displayed for the data object
    if (Select2Mounter.fuzzyMatch(data.text, params.term)) {
      var modifiedData = $.extend({}, data, true);

      // You can return modified objects from here
      // This includes matching the `children` how you want in nested data sets
      return modifiedData;
    }

    // Return `null` if the term should not be displayed
    return null;
  },

  start() {
    $(".select2").select2({
      placeholder: "Please select",
      matcher: Select2Mounter.fuzzyMatcher
    })
  }
}

export default Select2Mounter;
