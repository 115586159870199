import moment from "moment";

const DateFormatterMixin = {
  filters: {
    toLocalTime(datetime) {
      return moment
        .utc(datetime, "MMM DD, YYYY LT")
        .local()
        .format("MMM DD, YYYY LT");
    },
  }
};

export default DateFormatterMixin;