<template>
  <div :id="htmlInputId()" class="search-form-select-field">
    <input
      type="hidden"
      :name="inputName"
      :id="inputId"
      :value="value.value"
    />
    <vue-multiselect
      :options="dropdownOptions"
      v-model="value"
      label="label"
      track-by="label"
      select-label=""
    >
    </vue-multiselect>
  </div>
</template>

<script>
import VueMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css';

export default {
  name: "FormSearchSelect",
  components: { VueMultiselect },
  props: {
    placeholder: {
      type: String,
    },
    inputName: {
      type: String,
      required: true,
    },
    inputId: {
      type: String,
      required: true,
    },
    selectedValue: {
      type: String,
    },
    dropdownOptions: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      options: this.dropdownOptions,
      value: this.setSelectedValue() || {},
    };
  },
  methods: {
    setSelectedValue() {
      return this.dropdownOptions.find((element) => {
        return element.value == this.selectedValue
      })
    },
    htmlInputId() {
      return `search_select_${this.inputId}`;
    }
  }
};
</script>

<style lang="scss">
$dark-mode-background-color: #393e44;
$dark-mode-text-color: #BEBDBE;

body.dark-mode {
  .search-form-select-field,
  .multiselect__content-wrapper,
  .multiselect__tags {
    background-color: $dark-mode-background-color;
    color: $dark-mode-text-color;
    border: none;
    border-radius: 0.25rem;
  }

  .multiselect__option--highlight {
    background-color: lighten($dark-mode-background-color, 4%);
  }

  .multiselect__single,
  .multiselect__input, {
    background-color: $dark-mode-background-color;
    color: $dark-mode-text-color;
  }
}
</style>
