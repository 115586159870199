import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [
    "currencyIn", 
    "currencyOut", 
    "outgoingFee", 
    "resultingBlock", 
    "resultingCurrency",
    "resultingFee",
  ]

  connect() {
    this.calculate()
  }

  calculate() {
    let currencyIn  = this.currencyInTarget.value
    let currencyOut = this.currencyOutTarget.value
    let outgoingFee = this.outgoingFeeTarget.value

    if(!outgoingFee) {
      this.resultingBlockTarget.classList.add("d-none")
    }

    if(currencyIn && currencyOut && outgoingFee) {
      let url = new URL(this.data.get("url"))
      let params = {
        currency_in: currencyIn,
        currency_out: currencyOut,
        outgoing_fee: outgoingFee,
      }
      Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))

      fetch(url).
        then(response => response.json()).
        then(data => {
          let precision = data.precision
          let fee = parseFloat(data.fee).toFixed(precision)

          if(isNaN(fee)) {
            this.resultingBlockTarget.classList.add("d-none")
            this.resultingCurrencyTarget.textContent = ""
            this.resultingFeeTarget.textContent = ""
          } else {
            this.resultingBlockTarget.classList.remove("d-none")
            this.resultingCurrencyTarget.textContent = currencyIn
            this.resultingFeeTarget.textContent = fee
          }
        })
    }
  }

}
