<template>
  <div class="notifications-panel">
    <div class="notifications-content">
      <div class="notifications-header">
        <h5>Notifications</h5>
        <x-icon class="close" @click="closePopup"></x-icon>
      </div>
      <notifications-list :notifications="notifications"></notifications-list>
      <div class="notifications-link">
        <a :href="notificationsPath">See All</a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { XIcon } from "vue-feather-icons";
import NotificationsList from "./NotificationsList";

export default {
  name: "NotificationsPanel",
  props: ["notificationsPath", "notifications"],
  components: {
    XIcon,
    NotificationsList
  },
  methods: {
    closePopup() {
      this.$emit("closeNotificationPanel");
    }
  }
};
</script>

<style lang="scss" scoped>
.notifications-panel {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  min-width: 25rem;
  padding: 0.5rem 0 0.25rem;
  margin: 0.125rem 0 0;
  border-radius: 0.25rem;
  font-size: 0.9rem;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid white;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.notifications-header {
  background: white;
  display: flex;
  border-bottom: 2px solid #eee;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1.25rem 1rem;

  > h5 {
    font-size: 1rem;
    margin-bottom: 0;
  }

  > .close {
    width: 1rem;
    height: 1rem;
    cursor: pointer;
    font-weight: bold;
  }
}

.notifications-link {
  background: white;
  padding: 0.5rem 1.25rem;
  display: flex;
  justify-content: center;
}

$dark-mode-bg-color: #2a2e32;
$dark-mode-border-color: #44484d;

body.dark-mode {
  .notifications-panel,
  .notifications-header,
  .notifications-link {
    background-color: $dark-mode-bg-color;
  }

  .notifications-panel {
    border: 0;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  }

  .notifications-header {
    border-bottom: 2px solid $dark-mode-border-color;

    > .close {
      color: #fff;
    }
  }
}
</style>


